'use client';

import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Card, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';

import Label from 'src/components/label';
import Image from 'src/components/image';
import SelectGift from 'src/components/select-gift/select-gift';

import { IGift } from 'src/types/gift';
import { ICoupon, IMerchantsEntity } from 'src/types/coupons';

import { useRouter } from '../../../../routes/hooks';
import SanitizeHTML from '../../../../utils/sanitize';
import { useAppContext } from '../../../../contexts/AppContext';
import { RenderInfoPage } from '../render-info-page';
import { RenderCouponInfoPage } from '../../../generate-page/components/coupon/render-info-page';


const styleReselectModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '80vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

type Props = {
  selected?: boolean;
};

export const SelectCoupon = ({ selected }: Props) => {
  const isMobile = useResponsive('down', 'sm');
  const { t } = useTranslate();
  const { user } = useAuthContext();
  const { state, dispatch } = useAppContext();
  const { trigger } = state;
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [merchants, setMerchants] = useState<IMerchantsEntity[]>([]);
  const [gifts, setGifts] = useState<IGift | null>(null);
  const router = useRouter();
  const [giftPin, setGiftPin] = useState<{
    number: string;
    pin: string;
    id: string;
  } | null>(null);

  const smUp = useResponsive('up', 'sm');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '80%',
    maxWidth: '80vw',
    maxHeight: isMobile ? '100%' : '80vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };
  console.log('trigger', trigger);
  useEffect(() => {
    init();
  }, [user, trigger]);

  const init = async () => {
    try {
      const activityId = state.smbActivityId;
      const { data } = await API({
        params: {
          botId: BOT_ID,
          smbActivityId: activityId,
        },
        url: `card`,
        method: 'GET',
      });
      if (data?.cards?.length) {
        setCoupons(data.cards);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onClickViewMerchant = (merchantsEntities: IMerchantsEntity[]) => {
    setMerchants(merchantsEntities);
  };

  const onCloseViewMerchant = () => {
    setMerchants([]);
  };

  const onCloseReselectModal = () => {
    setGifts(null);
  };

  const onClickUseCoupon = async (cardId: string) => {
    if (cardId) {
      try {
        const { data } = await API({
          params: {
            cardId,
          },
          url: 'product/getProductStockByCardId',
          method: 'GET',
        });
        if (data?.payload.pin) {
          setGiftPin({
            number: data?.payload?.number,
            pin: data?.payload?.pin,
            id: cardId,
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onClickReselectCoupon = async (cardId: string, removeGiftProductId: string) => {
    try {
      const { data } = await API({
        url: `giftSelection/${cardId}`,
        method: 'GET',
      });
      if (data?.id) {
        const currentData: IGift = {
          ...data,
          items: data.items.filter((item: any) => item.productId !== removeGiftProductId),
        };
        setGifts(currentData);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onReselectedGift = () => {
    init();
    onCloseReselectModal();
  };

  return (
    <>
      <Grid container spacing={2}>
        {coupons.map((item) => (
          <Grid item md={selected ? 12 : 6} xs={12} key={item.id}>
            <Card>
              <Stack direction="row-reverse" gap={2} height="auto">
                <Stack
                  sx={{
                    width: '70%',
                    color: 'primary.main',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography sx={{ fontWeight: 800 }}>{item?.product?.title}</Typography>
                      <Label color="success" sx={{ mx: 1 }}>
                        {t(item.status)}
                      </Label>
                    </Stack>
                    <Typography sx={{ color: 'grey.600', fontSize: '18px' }}>
                      <SanitizeHTML html={item.product?.description ?? ''} />
                    </Typography>
                    {giftPin?.id && item?.id === giftPin?.id && (
                      <Stack direction="column">
                        <Typography sx={{ fontWeight: 800 }}>
                          {t('Your number')}: {giftPin?.number || 'N/A'}
                        </Typography>
                        <Typography sx={{ fontWeight: 800 }}>
                          {t('Your pin')}: {giftPin?.pin || 'N/A'}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  {!isMobile && (
                    <Stack mb={1}>
                      <Stack direction="row" gap={2} mt={2} height="auto" flexWrap="wrap">
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ borderRadius: '20px' }}
                          onClick={() => onClickUseCoupon(item.id)}
                        >
                          השתמש בקופון
                        </Button>
                        {!!item.product?.merchants?.length && (
                          <>
                            {!merchants?.length ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{ borderRadius: '20px' }}
                                onClick={() =>
                                  item.product?.merchants?.length && onClickViewMerchant(item.product?.merchants)
                                }
                              >
                                ספקים
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{ borderRadius: '20px' }}
                                onClick={() =>
                                  onCloseViewMerchant()
                                }
                              >
                                סגור
                              </Button>
                            )}
                          </>
                        )}
                        {item?.giftSelectionEvent?.reselection && (
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ borderRadius: '20px' }}
                            onClick={() =>
                              onClickReselectCoupon(item.giftSelectionEvent?.id, item.productId)
                            }
                          >
                            {t('Reselect')}
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
                <Box sx={{ width: '30%' }}>
                  <Image
                    src={uploadProductsImage(item.product?.mainImageURL)}
                    borderRadius={0}
                    sx={{ borderRadius: '0px', width: '100%', height: '100%', cursor: 'pointer' }}
                    onClick={() => router.push(`product/${item.product.id}`)}
                  />
                  <Box
                    sx={{
                      borderRadius: '10px',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      backgroundColor: 'secondary.contrastText',
                      m: 1,
                      width: '20%',
                    }}
                  >
                    {/* <Image src={item.brand} sx={{ borderRadius: '15px', width: '100%' }} /> */}
                  </Box>
                </Box>
              </Stack>
              {isMobile && (
                <Stack mb={1}>
                  <Stack direction="row" gap={2} mt={2} height="auto" flexWrap="wrap">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: '20px' }}
                      onClick={() => onClickUseCoupon(item.id)}
                    >
                      השתמש בקופון
                    </Button>
                    {!!item.product?.merchants?.length && (
                      <>
                        {!merchants?.length ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ borderRadius: '20px' }}
                            onClick={() =>
                              item.product?.merchants?.length && onClickViewMerchant(item.product?.merchants)
                            }
                          >
                            ספקים
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ borderRadius: '20px' }}
                            onClick={() =>
                              onCloseViewMerchant()
                            }
                          >
                            סגור
                          </Button>
                        )}
                      </>
                    )}
                    {item?.giftSelectionEvent?.reselection && (
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ borderRadius: '20px' }}
                        onClick={() =>
                          onClickReselectCoupon(item.giftSelectionEvent?.id, item.productId)
                        }
                      >
                        {t('Reselect')}
                      </Button>
                    )}
                  </Stack>
                </Stack>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* <Modal open={Boolean(merchants.length)} onClose={onCloseViewMerchant}> */}
      {/*  <Box sx={style}> */}
      {/*    <Typography variant="h6" component="h2" gutterBottom> */}
      {/*      This coupon can be used for */}
      {/*    </Typography> */}
      {/*    <Scrollbar sx={{ */}
      {/*      maxHeight: '400px', */}
      {/*      overflowY: 'auto', */}
      {/*    }}> */}
      {/*      <Grid container spacing={2}> */}
      {/*        {merchants.map((product) => ( */}
      {/*          <Grid item md={3} xs={12} key={product.id}> */}
      {/*            <Card */}
      {/*              onClick={() => router.push(`/merchant/${product?.id}`)} */}
      {/*              sx={{ */}
      {/*                pb: 1, */}
      {/*                maxWidth: '250px', */}
      {/*                cursor: 'pointer', */}
      {/*                display: 'flex', */}
      {/*                flexDirection: 'column', */}
      {/*                alignItems: 'center', */}
      {/*              }} */}
      {/*            > */}
      {/*              <Image */}
      {/*                src={product.mainImageURL ? uploadProductsImage(product.mainImageURL) : ''} */}
      {/*                sx={{ borderRadius: '15px' }} */}
      {/*              /> */}
      {/*              <Typography */}
      {/*                sx={{ */}
      {/*                  fontSize: '14px', */}
      {/*                  textWrap: 'balance', */}
      {/*                  mt: 1, */}
      {/*                }} */}
      {/*              > */}
      {/*                {product?.title} */}
      {/*              </Typography> */}
      {/*            </Card> */}
      {/*          </Grid> */}
      {/*        ))} */}
      {/*      </Grid> */}
      {/*    </Scrollbar> */}
      {/*    <Box> */}
      {/*      <Button */}
      {/*        variant="contained" */}
      {/*        color="primary" */}
      {/*        sx={{ borderRadius: '20px' }} */}
      {/*        onClick={onCloseViewMerchant} */}
      {/*      > */}
      {/*        {t('Close')} */}
      {/*      </Button> */}
      {/*    </Box> */}
      {/*  </Box> */}
      {/* </Modal> */}
      <Modal open={Boolean(gifts?.id)} onClose={onCloseReselectModal}>
        <Box sx={styleReselectModal}>
          <Box
            sx={{
              maxHeight: '80vh', // Ограничение высоты модального окна
              overflowY: 'auto', // Добавление вертикальной прокрутки
            }}
          >
            <SelectGift
              gift={gifts as IGift}
              customerId={user?.id}
              refreshData={onReselectedGift}
              isReselect
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: '20px' }}
              onClick={onCloseReselectModal}
            >
              {t('Close')}
            </Button>
          </Box>
        </Box>
      </Modal>
      {Boolean(merchants.length) && (
        <RenderCouponInfoPage
          card={coupons[0]}
        />
      )}
    </>
  );
};
